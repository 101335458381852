import React from 'react';

import { Form } from 'react-bootstrap';

import '../scss/creditPayoff.scss';

class Payoff extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      creditLimit: '',
      balance: '',
      payoffAmount: '$0.00',
      exceeded: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.state[e.target.id] = e.target.value;
    if (this.state.creditLimit === '' || this.state.balance === '') {
      this.setState({ payoffAmount: '$0.00' });
      return;
    }

    let creditLimit = parseFloat(this.state.creditLimit);
    let balance = parseFloat(this.state.balance);

    let utilization = balance / creditLimit;

    let actualPayoff;

    if (balance > creditLimit) {
      this.setState({ exceeded: true });
    } else {
      if (utilization <= 0.1) {
        this.setState({ exceeded: false });
        actualPayoff = balance;
      } else if (utilization >= 0.3) {
        this.setState({ exceeded: false });
        actualPayoff = balance - 0.25 * creditLimit;
      } else {
        this.setState({ exceeded: false });
        actualPayoff = balance - 0.1 * creditLimit;
      }

      actualPayoff = '$' + actualPayoff.toFixed(2);

      if (utilization > 0) {
        actualPayoff = actualPayoff + '+';
      }
      this.setState({ payoffAmount: actualPayoff });
    }
  }

  render() {
    return (
      <main>
        <section className="payoff">
          <h4>How much should you pay down your credit card?</h4>
          <h5>
            Fill in the below to receive Sequin&apos;s recommended pay off
            amount for excellent credit (if you can pay the full amount due,
            that&apos;s best!).{' '}
            <a href="https://www.sequincard.com/blog/top-credit-tips-for-women">
              Click here to learn more about Sequin&apos;s Top 10 Tips for
              Excellent Credit.
            </a>
          </h5>
          <Form style={{ marginTop: '25px' }}>
            <Form.Group>
              <Form.Label>Your current credit line</Form.Label>
              <Form.Control
                id="creditLimit"
                onChange={this.handleChange}
                type="number"
                className="input"
                placeholder="Enter your credit line"
              />
            </Form.Group>

            <Form.Group className="balance">
              <Form.Label>Your current balance</Form.Label>
              <Form.Control
                id="balance"
                onChange={this.handleChange}
                type="number"
                className="input"
                placeholder="Enter your current balance"
              />
            </Form.Group>
          </Form>

          <p className="payoff-amount">
            Recommended payoff amount:
            {this.state.exceeded === true ? (
              <b style={{ color: '#A82424' }}>
                {' '}
                Balance cannot exceed credit limit
              </b>
            ) : (
              <b style={{ color: '#60034c' }}> {this.state.payoffAmount}</b>
            )}
          </p>
          <br />

          <p>
            <b>Questions?</b> Get in touch at{' '}
            <a href="mailto:support@sequincard.com?subject=Sequin Payoff">
              support@sequincard.com
            </a>
            .
          </p>
        </section>
      </main>
    );
  }
}

export default Payoff;
